const doctifyProviderDomain = 'https://provider.doctify.com'
const endpoint = '/entry-tier'

const pathMap: { [key: string]: string } = {
  uk: '/en-gb/uk',
  ae: '/en-ae/ae',
  'en-ae': '/en-ae/ae',
  'ar-ae': '/en-ae/ae',
  at: '/de-at/at',
  'de-at': '/de-at/at',
  de: '/de-de/de',
  au: '/en-au/aus',
  ie: '/en-ie/ie',
  wpa: '/en-gb/uk'
}

const limitReviews = 10
const limitReviewsOnReviewPage = 10
const limitEndorsements = 10
const limitSpecialists = 10

const enum SpecialistPlans {
  ENTRY = 's_freemium',
  EXPERT = 's_expert',
  EXPERT_PRO = 's_pro',
  UNCLAIMED = 'unclaimed',
  NO_PLAN = 'no plan'
}

const enum PracticePlans {
  ENTRY = 'p_freemium',
  STARTER = 'p_starter',
  ESSENTIALS = 'p_essentials',
  PRO = 'p_pro',
  UNCLAIMED = 'unclaimed',
  NO_PLAN = 'no plan'
}

export const enum MediaTypes {
  WEBSITE = 'website',
  PUBLICATION = 'publication',
  VIDEO = 'video',
  PROFILE = 'profile',
  ARTICLE = 'article'
}

export const mediaTypesWithoutSocials = [
  MediaTypes.VIDEO,
  MediaTypes.PROFILE,
  MediaTypes.ARTICLE,
  MediaTypes.PUBLICATION,
  MediaTypes.WEBSITE
]

export const CONNECTION_COUNT_TRUST_BADGE_SPECIALIST = 20
export const CONNECTION_COUNT_TRUST_BADGE_PRACTICE = 100

export {
  doctifyProviderDomain,
  endpoint,
  pathMap,
  limitReviews,
  limitReviewsOnReviewPage,
  limitEndorsements,
  limitSpecialists,
  SpecialistPlans,
  PracticePlans
}

export enum RequestTypes {
  PHONE = 'Phone',
  EMAIL = 'Email',
  EXTERNAL_BOOKING_LINK = 'ExternalBookingLink'
}

export enum RequestSources {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile'
}

export const ONLINE = 'online'

export const enum ProfileTypes {
  PRACTICE = 'practice',
  SPECIALIST = 'specialist'
}
